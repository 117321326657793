import loadable from '@loadable/component';

import { Site } from '../../core/modules/sites/types/Site';
import { SeasonalEventMerchant } from '../../core/types/SeasonalEvent';
import { EventBattle } from '../../types/Battle';
import { getHawk } from '../../utils/global';

const lazyGetCustomTrackingIdServerside = loadable.lib(
  () => import('../../analytics-library/entry/getCustomTrackingIdServerside'),
);

const lazyGetCustomTrackingId = loadable.lib(
  () => import('../../analytics-library/entry/getCustomTrackingId'),
);

export const getSeasonalMerchantCustomTrackingId = async (
  articleUrl: string,
  site: Site,
  merchant: SeasonalEventMerchant,
  area: string,
  battle: EventBattle | null,
): Promise<string> => {
  const { customTrackingId: forcedCustomTrackingId } = getHawk();
  if (process.env.IS_SERVERSIDE) {
    const { getCustomTrackingIdServerside } =
      (await lazyGetCustomTrackingIdServerside.load()) as any;
    return getCustomTrackingIdServerside({
      params: {
        pageUrl: articleUrl,
        site,
        merchant,
        area,
        battle,
      },
      getCustomTrackingId: forcedCustomTrackingId,
    });
  }
  const { getCustomTrackingId } = (await lazyGetCustomTrackingId.load()) as any;
  return getCustomTrackingId({
    params: {
      pageUrl: articleUrl,
      site,
      merchant,
      area,
      battle,
    },
    getCustomTrackingId: forcedCustomTrackingId,
  });
};
